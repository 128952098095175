import React from 'react'
import css from './Footer.module.css'
import Logo from "../../assets/logo.png"

import {LocationMarkerIcon,
    InboxIcon ,
     PhoneIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
 } from "@heroicons/react/outline"


export default function Footer() {
  return (
    <div className={css.FooterWrapper}>
       <hr/>


       <div className={css.Footer}>
          <div className={css.logo}>
             <img src={Logo} alt=""/>    
             <span>Kittriz05</span>      
             </div>
           <div className={css.block}>
            <div className={css.detail}>
                <span>Contact Us</span>
                <span className={css.pngLine}>
                    <LocationMarkerIcon className={css.icon} />
                    <span>Tilottama-06, Rupandehi, Nepal</span>
                </span>
                <span className={css.pngLine}>
                    <PhoneIcon className={css.icon} />
                    <a href="tel:123-456-7890">123-456-7890</a>
                </span>
                <span className={css.pngLine}>
                   
                    <InboxIcon className={css.icon} />
                    <a href="mailto:support@kittriz05@gmail.com">support@kittriz05.com</a>
                </span>
            </div>
            </div>  
            <div className={css.block}>

                <div className={css.detail}>
                <span>Account</span>
                <span className={css.pngLine}>

                  <LoginIcon className={css.icon} />
                  <p>Sign In</p>
                </span>

                    
                </div>
            </div>
            <div className={css.block}>
 
              <div className={css.detail}>
<span>Company</span>
<span className={css.pngLine}>

  <UsersIcon className={css.icon} />
  <a href="/about">
    <p>About Us</p>
  </a>
</span>

    
             </div>
              </div>
              <div className={css.block}>

    <div className={css.detail}> 
    <span>Resources</span> 
    <span className={css.pngLine}>

  <LinkIcon className={css.icon} />
  <p>Privacy & Terms</p>
</span>

    
</div>
                </div>

       </div>

       <div className={css.copyright}>
         <span>Copyright @2022 by Kittriz05</span>
         <span>All rights reserved</span>

       </div>
       <hr/>
    </div>
  )
}
