import React, {useState} from 'react'
import css from './Header.module.css'
import Logo from '../../../src/assets/logo.png'
import {AiOutlineShoppingCart} from 'react-icons/ai'
import {HiOutlineBars4} from 'react-icons/hi2'

export const Header = () => {

  const [showMenu, setshowMenu] = useState(true)

  const toggleMenu = () =>{
    setshowMenu((showMenu)=> !showMenu)

  }
  return (
     <div className={css.container}>
        <div className={css.logo}>
         <img src={Logo} alt='' />
         &nbsp;
         <span>KITTRIZ05</span> 
        </div>

        <div className={css.right}>
           
          <div className={css.bars} onClick={toggleMenu}>
            <HiOutlineBars4 />
          </div>
             <ul className={css.menu} style={{display: showMenu? 'flex' : 'none'}}>
           <li>Collections</li>
           <li>Brands</li>
           <li>New</li>
           <li>Sales</li>
             </ul>
             
             
            <input type="text" className={css.search} placeholder='Search'/>
            <AiOutlineShoppingCart className={css.cart}/>
            
        </div>
     </div>
    )
}
